import React from "react";
import { useStoreon } from "storeon/react";
import { Button, Space, version } from "antd";

import DeletedFilesTable from "./DeletedFilesTable.js";
import BotEmailTemplate from "./BotEmailTemplate.js";

import { MESSAGE_NO_ACCESS } from "../../auth/authHelper";

import SheetJS from './xlsx'

const Settings = () => {
  const { auth, dispatch } = useStoreon("auth");
  const access = (auth.user_role == "admin");

  if (!access) return MESSAGE_NO_ACCESS;

  return (
    <>
      <Space direction="vertical">
      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "crt_mail_content_editor" })
        }
      >
        Certificate mail template editor
      </Button>

      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "country_lookup_codes" })
        }
      >
        Edit country lookup codes
      </Button>
      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "shipment_statuses" })
        }
      >
        Edit shipment statuses 
      </Button>
      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "deleted_files" })
        }
      >
        Manage deleted files
      </Button>

      <DeletedFilesTable/>

      </Space>
      <hr/>
      <h1> processXSLX: </h1>
      <SheetJS/>

      <h1>antd version: {version}</h1>
    </>
  );
};

export default Settings;
