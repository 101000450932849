import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Anchor, Link, List } from "antd";
import { ShipmentToolbar } from "./ShipmentToolbar";
import ShipmentFilterForm from "./ShipmentFilterForm";
import ShipmentType, { ShipmentTypeDecode } from "../shared/ShipmentType";

import moment from "moment";
import { GET_SHIPMENTS } from "./graphql/shipments_graphql.js";

// check
import { Input, Table, Tabs } from "antd";
import FormBtn from "../shared/FormBtn";
import { FilesIcon } from "../shared/FilesIcon";
import CompanySelect from "../shared/CompanySelect";
import WarehouseSelect from "../shared/WarehouseSelect";
import ShipmentStatuses from "../shared/ShipmentStatuses";
import { isSupplier, isAdmin, isShipper, MESSAGE_NO_ACCESS } from "../../auth/authHelper";


import { Layout } from 'antd';
const { Header, Footer, Sider, Content } = Layout;

const ShipmentsTable = (props) => {
  const { selection, dispatch, filter } = useStoreon("selection", "filter");
  const type = props.type;
  //const [ shipmentId, setShipmentId ] = useState(selection.shipmentId);
  const { auth } = useStoreon("auth");
  const [ prevFilter, setPrevFilter ] = useState();
  const [ state, setState ] = useState({
    filteredInfo: {status: ["new"]},
    sortedInfo: null,
    selectedRowKeys: selection.shipmentRows
  });

  let { sortedInfo = {}, filteredInfo = {}} = state;

  const filter_input_helper_props = (setSelectedKeys, confirm, key) => ({
    onPressEnter: (e) => {
      setSelectedKeys(e.target.value?[e.target.value]:[]);
      if (confirm) confirm({ closeDropdown: true });
    },
    onBlur: (e) => {
      setSelectedKeys(e.target.value?[e.target.value]:[]);
      if (confirm) confirm({ closeDropdown: true });
    },
     _onChange: (e) => {
       //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, key: e.target.value?[e.target.value]:null}}))
       setSelectedKeys(e.target.value?[e.target.value]:[]);
       if (confirm) confirm({ closeDropdown: true });
    },
  });

  // columns: begin /////////////////////////////////////////////
  const columns = [
  {
    title: "Type",
    key: "type",
    width: "3%",
    render: record => ShipmentTypeDecode(record.type),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <ShipmentType
      style={{ width: "100%"}}
      allowClear
      onChange={(value) => {
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "type": (value?[value]:null)}}))
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: filteredInfo["type"] || null,
    onFilter: (value, record) => record.type === value,
  },
  {
    title: "State",
    width: "3%",
    dataIndex: "state",
    render: state => decode_statuses(state),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <ShipmentStatuses
      style={{ width: "100%"}}
      allowClear
      onChange={(value) => {
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "state": value?[value]:null}}))
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: filteredInfo["state"] || null,
    onFilter: (value, record) => record.state === value,
  },
  // &#8209;
  {
    title: "Created",
    width: "3%",
    dataIndex: "created_at",
    render: date => {
       return {
            props: { style: { whiteSpace: "nowrap" } },
            children: <>{moment(date).format("DD-MM-YYYY")} </>
          };
    },
    sorter: (a, b) => {
      if (a.created_at < b.created_at) { return -1; } else if (a.created_at > b.created_at) { return 1; }
      return 0;
    },
  },
  {
    title: "Depature",
    width: "3%",
    dataIndex: "departure_date",
    render: date => {
       return {
            props: { style: { whiteSpace: "nowrap" } },
            children: <>{moment(date).format("DD-MM-YYYY")} </>
          };
    },
    sorter: (a, b) => {
      if (a.departure_date < b.departure_date) { return -1; } else if (a.departure_date > b.departure_date) { return 1; }
      return 0;
    },
  },
  {
    title: "Shipper",
    dataIndex: ["shipper", "name"],
    filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <CompanySelect
      style={{width: "100%"}}
      role="shipper"
      onChange={(value) => {
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "shipper.name": value?[value]:null}}))
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: filteredInfo["shipper.name"] || null,
    onFilter: (value, record) => record.shipper_id === value,
  },
  {
    title: "Truck",
    dataIndex: ["truck", "reg_number"],
    key: "truck_reg_number",
    filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
      allowClear
      {...filter_input_helper_props(setSelectedKeys, confirm, "truck_reg_number")}
    />,
    filteredValue: filteredInfo["truck_reg_number"] || null,
    onFilter: (value, record) => record.truck?.reg_number.toUpperCase().includes(value.toUpperCase()),
    width: "3%",
    render: date => {
       return {
            props: { style: { whiteSpace: "nowrap" } },
            children: date
          };
    },
  },
  {
    title: "Trailer",
    dataIndex: ["trailer", "reg_number"],
    key: "trailer_reg_number",
    filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
      allowClear
      {...filter_input_helper_props(setSelectedKeys, confirm, "truck_reg_number")}
      _onFilterDropdownVisibleChange={ (visible) => {
        if (!visible) {
          if (confirm) confirm({ closeDropdown: true });
        }
      }}
    />,
    filteredValue: filteredInfo.trailer_reg_number || null,
    onFilter: (value, record) => record.trailer?.reg_number.toUpperCase().includes(value.toUpperCase()) ,
    width: "3%",
    render: date => {
       return {
            props: { style: { whiteSpace: "nowrap" } },
            children: date
          };
    },
  },
  {
    title: "Warehouse",
    dataIndex: ["warehouse", "name"],
    todo_more: "name, city, street, house_no",
    filteredValue: filteredInfo["warehouse.name"] || null,
    // filterDropdown: <WarehouseSelect ... require company_id, todo: modify WarehouseSelect+mode=all?
    filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
      allowClear
      {...filter_input_helper_props(setSelectedKeys, confirm, "truck_reg_number")}
    />,
    onFilter: (value, record) => record.warehouse?.name.toUpperCase().includes(value.toUpperCase()) ,
  },
  {
    title: "Files",
    width: "3%",
    render: record => <FilesIcon id={record.id} dataModel="order" count={record.files.aggregate.count} />
  },
  ];
  // columns: end ///////////////////////////////////////////////

  const onSelectChange = _selectedRowKeys => {
debugger;
    setState((prev) => ({...prev, selectedRowKeys: _selectedRowKeys }));
    dispatch("selectRows", { model: "shipment", rowKeys: _selectedRowKeys });
  };

  const { selectedRowKeys } = state;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return { disabled: false };
      //return { disabled: (record.shipment&&!isAdmin()) };
    },
    hideDefaultSelections: false,
    selections: [
// key <=> id?
//        Table.SELECTION_ALL,
//        Table.SELECTION_INVERT,
//        Table.SELECTION_NONE,
        {
          key: 'data-all',
          text: 'Select All Data',
          onSelect: changableRowKeys => {
            onSelectChange(data.shipments.map((a) => a.id));
          },
        },
        {
          key: 'data-none',
          text: 'Clear All Data',
          onSelect: changableRowKeys => {
            onSelectChange([]);
          },
        },
    ]
  };

  const filterChangeHandler = (a, b, c, d) => {
debugger;
    console.log("****************** ShipmentTable.filterChangeHandler: fired", a, b, c, d);
  };

  const clearSelection = () => {
    // clear selection
    dispatch("selectRows", { model: "shipment", rowKeys: [] });
    setState((prev) => ({...prev, selectedRowKeys: []}));
  };

  const handleChange = (pagination, filters, sorter, extra) => {
debugger;
    console.log("****************** ShipmentTable.handleChange: fired", pagination, filters, sorter);
    setState((prev) => ({...prev, 
      filteredInfo: filters,
      sortedInfo: sorter,
    }));
    if (extra.action === "filter") {
debugger;
      const filteredRowKeys = extra.currentDataSource.map((r) => r.id);
      const intersection = selectedRowKeys.filter(x => filteredRowKeys.includes(x));
      dispatch("selectRows", { model: "shipment", rowKeys: intersection });
      setState((prev) => ({...prev, selectedRowKeys: intersection }));
    }
  };

  const { shipperId, active, range, state: f_state } = filter.shipment;

  const { loading, error, data } = useQuery(GET_SHIPMENTS, {
    variables: {
      condition: {
        _and: [
          { shipper_id: shipperId? { _eq: shipperId } : {} },
          { active: active? { _eq: active } : {} },
          { departure_date: (range && range[0])? { _gt: range[0] } : {} },
          { departure_date: (range && range[1])? { _lt: range[1] } : {} },
          { id: props.excluded_id? { _neq: props.excluded_id } : {} },
          { type: type? { _eq: type } : {} },
          { state: f_state?.length? { _in: f_state } : {} }
        ]
      }
    }
    
  });

  ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("****************** ShipmentTable.useEffect(): fired", data?"data:on":"data:off", filter.shipment);
  });
  useEffect(() => {
    console.log("****************** ShipmentTable.useEffect() with ret: fired", data?"data:on":"data:off", filter.shipment);
    return () => {
      console.log("****************** ShipmentTable.useEffect(ret): fired", data?"data:on":"data:off", filter.shipment);
    }
  });
  useEffect(() => {
    console.log("****************** ShipmentTable.useEffect([]): fired", data?"data:on":"data:off", filter.shipment);
  }, []);

/*
  useEffect((e) => {
    console.log("useEffect: fired ", e);
    if (!filter.shipment.range || filter.shipment.range == "") {
      dispatch("setShipmentFilter", {...filter.shipment, range: [moment().add(-3, 'days').startOf('day'), moment().endOf('day')]});
    }
    return ((v) => { console.log("useEffect: ret: ", v); });
  }, []);
*/

/*
  // refetch => toolbar filter changed?
  useEffect((e) => {
debugger;
    if (data) {
      // setState((prev) => ({...prev, selectedRowKeys: selection.shipmentRows}));
      setState((prev) => ({...prev, selectedRowKeys: []}));
    }
  }, [data]);
*/

  useEffect((e) => {
debugger;
    console.log("****************** ShipmentTable.useEffect([filter.shipment]): fired", data?"data:on":"data:off", filter.shipment);
    if (!prevFilter) {
      console.log("****************** ShipmentTable.useEffect([filter.shipment]): prev is empty", filter.shipment);
    } else {
      console.log("****************** ShipmentTable.useEffect([filter.shipment]): ", prevFilter, filter.shipment);
      console.log("****************** ShipmentTable.useEffect([filter.shipment]): ", JSON.stringify(prevFilter), JSON.stringify(filter.shipment) );
      if (JSON.stringify(prevFilter) != JSON.stringify(filter.shipment)) {
        console.log("****************** ShipmentTable.useEffect([filter.shipment]): clear selection" );
        clearSelection();
      }
    }
    setPrevFilter(filter.shipment);
  }, [filter.shipment]);

  ///////////////////////////////////////////////////////////////////////////

  if (!(isAdmin() || isShipper())) return "You have not suitable permissions";

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const statuses_data = data?.shipment_statuses.reduce((r, s, i) => {
    r [s.status] = {info: s.info, desc: s.description, pos: s.position };
    return (r);
  }, []);

  const decode_statuses = (status) => {
    return statuses_data[status].info;
  };

  const active_columns = !isSupplier()?columns:columns.filter(col => !col.x_supplier);

  return (
    <>
    <ShipmentFilterForm/>
    <Layout>
    <Header className="site-layout site-header"
          style={{
            height: "32px",
          }}
    >
        <ShipmentToolbar single={false} mode={props.excluded_id?"select":""} />
    </Header>
    <Content
      style={props.excluded_id?{
        overflowY: "auto",
        height: "52vh", /* move to parents */
      }
      : {
        overflowY: "auto",
        height: "82vh", /* move to parents */
      }
      }
    >
      <Table
        bordered
        rowKey="id"
        rowSelection={rowSelection}
        rowClassName={(record) => "row-v-alig-top"+(record.state==='N'?" bg-light-green":"") }
        onChange={handleChange}
        columns={active_columns}
        dataSource={data.shipments}
        size="small"
        onRow={(record, _) => {
          return {
            onClick: () => dispatch("select", { model: "shipment", id: record.id })
          };
        }}

/*
        onExpand={(_, record) => {
          dispatch("select", { model: "shipment", id: record.id });
        }}
        expandedRowRender={
          record => (
          <Tabs defaultActiveKey="1" tabPosition="left">
            <TabPane tab="Order" key="1"><OrderTabPane src={record}/></TabPane>
            <TabPane tab="Parse" key="2">
              <OrderFileList id={record.id} />
            </TabPane>
          </Tabs>
        )}
*/
      />

    </Content>
   </Layout>
   </>
  );
};

export default ShipmentsTable;
