import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, message, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const API_INVOICE   = process.env.REACT_APP_SERVER_URL + '/invoiceninja/invoices';
const API_DOCUMENTS = process.env.REACT_APP_SERVER_URL + '/invoiceninja/documents';

// Функции для работы с API Invoiceninja
const getInvoice = async (invoiceId) => {
  const response = await fetch(`${API_INVOICE}/${invoiceId}`);
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

const createInvoice = async (invoiceData) => {
  const response = await fetch(API_INVOICE, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(invoiceData)
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

const updateInvoice = async (invoiceId, invoiceData) => {
  const response = await fetch(`${API_INVOICE}/${invoiceId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(invoiceData)
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

// file ////////////////////////////////////////////
const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await fetch(`${API_INVOICE}/${file.invlice_id}/upload`, {
    method: 'POST',
    body: formData
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

const deleteFile = async (fileId) => {
  const response = await fetch(`${API_DOCUMENTS}/${fileId}`, {
    method: 'DELETE'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};
// file ////////////////////////////////////////////

////////////////////////////////////////////////////
const InvoiceForm = ({ initialValues, invoice_id: invoiceId }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (invoiceId) {
      setIsLoading(true);
      getInvoice(invoiceId)
        .then((p_invoice) => {

alert('invoice: '+JSON.stringify(p_invoice));
debugger;
          const invoice = p_invoice.data.data;

          form.setFieldsValue({
            number: invoice.number,
            po_number: invoice.po_number,
            discount: invoice.discount,
            amount: invoice.amount
          });
          setFiles((invoice.documents||[]).map(file => ({
            uid: file.id,
            name: file.name,
            url: file.url,
            invoice_id: invoice.id,
          })));
        })
        .catch((error) => {
          message.error(`Error getInvoice: ${error.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [invoiceId, form]);

  const onFinish = async (values) => {
    setIsUpdating(true);
    let result;
    if (invoiceId) {
      result = await updateInvoice(invoiceId, values);
    } else {
      result = await createInvoice(values);
    }
    message.success('Invoice saved');
    setIsUpdating(false);
  };

  const onUpload = async ({ file }) => {
debugger;
    const result = await uploadFile(file);

    if (file.status === 'done') {
      const uploadedFile = file.response;
      setFiles([...files, {
        uid: uploadedFile.id,
        name: uploadedFile.filename,
        url: uploadedFile.url,
        invoice_id: invoiceId,
      }]);
    }
  };

  const onRemove = async (file) => {
debugger;
    if (file.uid !== undefined) {
      await deleteFile(file.uid);
    }
    setFiles(files.filter(f => f.uid !== file.uid));
  };

  const beforeUpload = (file) => {
    // additional checks in need
    return true; 
  };

  const uploadButton = (
    <div>
      <PlusOutlined type="plus" />
      <div style={{ marginTop: 8 }}>Add file</div>
    </div>
  );

  return (
<Spin spinning={isLoading}>
  <Form
    form={form}
    layout="vertical"
    initialValues={initialValues}
    onFinish={onFinish}
  >
    <Form.Item
      label="Invoice number"
      name="number"
      rules={[{ required: true, message: 'Please input invoice number' }]}
    >
      <Input  />
    </Form.Item>
    <Form.Item
      label="Discount"
      name="discount"
    >
      <Input  />
    </Form.Item>
    <Form.Item
      label="Amount"
      name="amount"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Po number"
      name="po_number"
      rules={[{ required: true, message: 'Please input PO number' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item label="Files">
      <Upload
        listType="text"
        fileList={files}
        customRequest={onUpload}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
      >
        {files.length >= 3 ? null : uploadButton}
      </Upload>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" loading={isUpdating}>
        Save
      </Button>
    </Form.Item>
  </Form>
</Spin>
);
}

export default InvoiceForm; 
