// TODO: form.isFieldsTouched() => onFieldsChange()
// and compare form with all fields inital state with current

import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Form, Input, Modal, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { MESSAGE_NO_ACCESS } from "../../auth/authHelper";

const TransactionsRulesTable = (props) => {
  const [form] = Form.useForm();
  const { auth, ui, dispatch } = useStoreon("auth", "ui", "selection");
  const { component } = ui.visibleForm;
  const { company_id, ...otherProps } = props;

  const [, forceUpdate] = useState({});
  const access = (auth.user_role == "admin");

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (data?.rules) {
      form.setFieldsValue(data);
    }
  });

  const closeForm = () => {
    // dispatch("hideForm");
  };

  const [upsert_rules] = useMutation(UPSERT_TRANSACTIONS_RULES, {
    refetchQueries: ["GetTransactionsRules", "Companies"], // Companies -> for tags

    // onCompleted: () => {
    //   form.resetFields();
    //   closeForm();
    // },
  });

  const [remove_rule] = useMutation(DELETE_TRANSACTIONS_RULE, {
    // do local remove() and delete();
    refetchQueries: ["GetTransactionsRules", "Companies"], // Companies -> for tags
     onCompleted: (r) => {
        // alert("remove_rule: done");
    },
  });

  const { loading, error, data } = useQuery(GET_TRANSACTIONS_RULES, {
    fetchPolicy: "cache-and-network",
    skip: !access || !company_id,
    variables: { id: company_id }, 
  });

  // if (data) alert(JSON.stringify(data));

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {

       //alert(JSON.stringify(values));
        values.rules.forEach((v) => {
          delete v.__typename;
          const objs = {objects: [{ ...v, company_id: company_id }]};
          upsert_rules ({
            variables: objs,
          });
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  if (!access) return MESSAGE_NO_ACCESS;

  return (
    <div>
        <Form 
           _title="Transactions rules"
           layout="inline" 
           autoComplete="off"
           form={form} 
           initialValues={data}
           onFinish={handleSubmit}
           onCancel={closeForm}
         >
          <Form.List name="rules" layout="inline">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field) => {

//              alert(JSON.stringify(fields));
//              alert(JSON.stringify(add));
//              alert(JSON.stringify(remove));

                  return (<>
                    <Row align="left" justify="space-between" key={field.key}>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, "rule"]}
                          fieldKey={[field.fieldKey, "rule"]}
                        >
                          <Input placeholder="rule" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item 
                          name={[field.name, "button_delete"]}
                          fieldKey={[field.fieldKey, "button_delete"]}
                        >
                          <MinusCircleOutlined
                            style={{ margin: 10, color: "red" }}
                            onClick={() => {
                              // cancel with empty fields
                              console.log("remove_rule: ", JSON.stringify(field), (field.key?data.rules[field.key]?.id:""));
                              console.log(field.key);
                              console.log(data.rules[field.key]?.id);

			      if (field.key == null || !data.rules[field.key]?.id) remove(field.key);
			      else
                              if (field.key != null && data.rules[field.key]?.id)
                                remove_rule({
                                  variables: {
                                    id: data.rules[field.key].id,
                                  }
                                 // TODO: remove localy if mutation ok & skip refetchQueries in this case
                                 //remove(field.key);
                                }		
                            )}}
                          />
                        </Form.Item>
                      </Col>
                      </Row>
                       </>
                  );
                })}
               <Row align="left" justify="space-between" key={"toolbar_"+company_id}>
                <Col span={12}>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => { add(); }}
                  >
                    <PlusOutlined /> Add Rule
                  </Button>
                </Form.Item>
                </Col>
                <Col span={12}>
               <Form.Item shouldUpdate >
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched() ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                  >
                    Submit
                  </Button>
                )}
              </Form.Item>
	     </Col>
            </Row>
              </div>
            )}
          </Form.List>
        </Form>
    </div>
  );
};

export default TransactionsRulesTable;

const GET_TRANSACTIONS_RULES = gql`
  query GetTransactionsRules ($id: Int!) {
    rules: p_transactions_rules (
      where: { company_id: {_eq: $id}},
      order_by: { id: asc }
    ) 
    {
      id
      company_id
      rule
    }
  }
`;

const DELETE_TRANSACTIONS_RULE = gql`
  mutation DeleteTransactionsRules ($id: Int!) {
    rules: delete_p_transactions_rules (where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const UPSERT_TRANSACTIONS_RULES = gql`
  mutation UpsertTransactionsRules ($objects: [p_transactions_rules_insert_input!]!) {
    rules: insert_p_transactions_rules(
      objects: $objects
      on_conflict: {
        constraint: p_transactions_rules_pkey
        update_columns: [rule]
      }
    ) {
      affected_rows
    }
  }
`;
/*
          <Form.List name="rules">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field) => {
                  return (
                    <Row align="left" justify="space-between" key={field.key}>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, "company_id"]}
                          fieldKey={[field.fieldKey, "company_id"]} >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name={[field.name, "rule"]}
                          fieldKey={[field.fieldKey, "rule"]}
                        >
                          <Input placeholder="rule" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <MinusCircleOutlined
                            style={{ margin: 10, color: "red" }}
                            onClick={() => {
                              // cancel with empty fields
                              if (field.key && data.rules[field.key]?.id)
                              remove_rule({
                                variables: {
                                  id: data.rules[field.key].id,
                                },
                              });
                              // remove(field.name);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add code
                  </Button>
                    <Button type="primary" htmlType="submit">
                  Submit
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>

*/