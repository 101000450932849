import React, { Component, useState, useEffect } from "react";
import { Select, Button, Checkbox, Radio } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { useStoreon } from "storeon/react";
import { logout, logout_all } from "../../auth/authHelper"; 
import { AUTH_TOKEN, REFRESH_TOKEN } from "../../constant";

const Option = Select.Option;

const TestPage = (props) =>  {

  console.log("TestPage: props: ", props);

  const [ state, setState ] = useState({ls: true, dp: true, rst: 4});
  const { dispatch, auth } = useStoreon("auth");
  const client = useApolloClient();

  const { loading, error, data } = useQuery(GET_TRUCK_TYPES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("return ...");

  const real_logout = () => {
        console.log("logout: real_logout");
        logout(client);
        dispatch("setAuthData", { authenticated: false });
  };

  const local_logout = (e) => {
      console.log("logout: local_logout:", state);
      if (state.ls){
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
      }
      if (state.dp)
        dispatch("setAuthData", { authenticated: false });
      
     if (state.rst === 1 || state.rst === 2)
      client.clearStore().then(() => {
              console.log("logout: clear cache");
              if (state.rst === 2) client.resetStore().then(() => console.log("logout: reset cache"));
      });
     if (state.rst === 3)
      client.cache.reset().then(() => {
            console.log("logout: cache reset");
            // history.push('/login')
      });
  };

  const changeHandler = (e) => {
    const { name, value} = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  return (
           <>
            <p>TestPage: {auth.authenticated?"auth":"not auth"}</p>
            <Checkbox name="ls" checked={state.ls} onChange={changeHandler} >local store</Checkbox>
            <Checkbox name="dp" checked={state.dp} onChange={changeHandler} >dispatch</Checkbox>
            <br/>
            <Radio.Group name="rst" onChange={changeHandler} value={state.rst}>
              <Radio value={1}>clearStore</Radio>
              <Radio value={2}>clearStore+resetStore</Radio>
              <Radio value={3}>cache.reset</Radio>
              <Radio value={4}>none</Radio>
            </Radio.Group>
            <br/>
            <Button onClick={real_logout}>logout: real</Button>
            <Button onClick={local_logout}>logout: test</Button>
            <br/>
            <Select style={{width: "400px"}}>
              {data.truck_types.map((item, key) => {
                return (
                  <Option key={key} value={item.type}>
                    {item.type}
                  </Option>
                );
              })}
            </Select>
           <hr size="1px"/>
           <div style={{width: "100px"}}>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           <p>just a log string just a log string just a log string just a log string just a log string <br/></p>
           </div>
           </>
  );
}

const GET_TRUCK_TYPES = gql`
  query TruckTypes {
    truck_types  {
      type
    }
  }
`;

export default TestPage;
