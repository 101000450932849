import React, { useEffect, useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const debug = false;

const groupBy = (arr, fn) =>
   arr
  .map(typeof fn === 'function' ? fn : val => val[fn])
  .reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i]);
    return acc;
  }, {});

const InvoiceClientSelect = ({ company_id, clients, ...otherProps }) => {
  const [ options, setOptions ] = useState();
  
  useEffect(() => {
    if (!clients?.length) return;
    const groups = groupBy(clients, "name");

    const options = [];
    for (var client_name in groups) {       
      const grp = groups[client_name].map((c) => ({ client_id: c.id,  currency_id: c.settings.currency_id }));
      options.push({client_name: client_name, client_grp_info: grp });
    }
    setOptions (options);

  }, [clients]);

  return (
    <>
    {debug && JSON.stringify(options)}
    <Select
      {...otherProps}
      allowClear
      showSearch
      placeholder={"Select client"}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options?.map((item, key) => {
        return (
          <Option key={key} value={JSON.stringify(item.client_grp_info)} >
            {item.client_name}
          </Option>
        );
      })}
    </Select>
    </>
  );
};

export default InvoiceClientSelect;
