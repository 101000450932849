import React, { useState, useEffect } from 'react';
import { useStoreon } from "storeon/react";
import { Radio, Space } from 'antd';

import InvoicesTable from "./InvoicesTable";
import InvoiceClientSelect from './InvoiceClientSelect'; 

import { currency_decode } from './InvoiceConstants'; 

const CLIENT_ID_PREFIX = "florunner";
const API_CLIENTS = process.env.REACT_APP_SERVER_URL + '/invoiceninja/clients';

const debug = false;

const Invoices = () =>  {
  const { auth } = useStoreon("auth");
  const [data, setData] = useState([]);
  const [clientIdx, setClientIdx] = useState();
  const [clientsInfo, setClientsInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100, // collect all clients
    total: 0,
  });

  // 
  const fetchClients = async (currentPage, perPage) => {
    setIsLoading(true);

    try {
      // %23
      const sep = '%23'; // #
      const company_key = `${CLIENT_ID_PREFIX}${sep}${auth.company_id}`   
      const url = `${API_CLIENTS}?page=${currentPage}&per_page=${perPage}&filter=${company_key}`;

      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const { data: jsonResponse } = await response.json();
      if (!jsonResponse?.data || !jsonResponse?.meta?.pagination) throw new Error('Bad response structure');

      setData(jsonResponse.data);
      setPagination({ ...pagination, total: jsonResponse.meta.pagination.total });

    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Loading data on first render and page change 
  useEffect(() => {
    fetchClients(pagination.current, pagination.pageSize);
  }, [pagination.current]);

  useEffect(() => {
    // if (!clientsInfo) { setClientIdx(); return; }

    if (clientsInfo?.length === 1)  setClientIdx(0); // clientsInfo[0].client_id
    else setClientIdx();

  }, [clientsInfo]);

    if (isLoading) return 'loading ...';

    return <> 
      { data && debug && <>{JSON.stringify(data)} <br /></> } 
      { clientsInfo && debug && <>{JSON.stringify(clientsInfo)} <br /></> } 
       
      <InvoiceClientSelect 
        clients={data} 
        onChange={ (v) => { setClientsInfo( v ? JSON.parse(v) : null); }  } />

      { clientsInfo && clientsInfo.length > 1 &&
        <Radio.Group
          buttonStyle="solid" 
          onChange={(v) => { alert('Currency selected:'+v.target.value); setClientIdx(v.target.value) }}
        >
         { clientsInfo.map((v, i) => <Radio.Button key={i} value={i}>{currency_decode [v.currency_id]}</Radio.Button>) }
        </Radio.Group>
      }
      {/* clientIdx = 0 valid value */}
      { (clientIdx || clientIdx === 0)  && <InvoicesTable client_idx={clientIdx} clients={data}/> }

     </>;
}

export default Invoices;
