import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useStoreon } from "storeon/react";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useApolloClient } from '@apollo/client';
import { Button, Row, Col, Space, Form, InputNumber, Input, Checkbox, Modal, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import CompanySelect from "../shared/CompanySelect";
import CompanyFee from "../shared/CompanyFee";
import TransactionTypes from "../shared/TransactionTypes";
import { getUserTenant, MESSAGE_NO_ACCESS } from "../../auth/authHelper";

//import { INSERT_TRANSACTIONS, UPDATE_TRANSACTION } from "./graphql/transactions_graphql.js";
import { INSERT_TRANSACTIONS, UPDATE_TRANSACTIONS_AND_INSERTS } from "./graphql/transactions_graphql.js";

import { AUTH_TOKEN, REFRESH_TOKEN } from "../../constant";

const TransactionAssignForm = (props) => {
  const [ form ] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { auth } = useStoreon("auth");
  const { action, component, mode } = ui.visibleForm;
  const { transaction_assignId } = selection;
  const [ perms, setPerms ] = useState({});
  const [ typeComputed, setTypeComputed ] = useState();
  const [ addMyCompanyId, setAddMyCompanyId ] = useState();
  const [ companyId, setCompanyId ] = useState();
  const [ fee, setFee ] = useState();
  const [ returnNoFee, setReturnNoFee ] = useState(false);
  const [ wait, setWait ] = useState(false);
  const client = useApolloClient();
  const access = (auth.user_role == "admin");

  const { loading, error, data, refetch } = useQuery(GET_TRANSACTION, {
    skip: !access||!(component === "transaction_assign" && action === "edit")||!transaction_assignId,
    variables: {
      id: transaction_assignId
    }
  });

  const [mutate] = useMutation( UPDATE_TRANSACTIONS_AND_INSERTS, {
      //variables: { id: transactionId },
      refetchQueries: ["Transactions", "Accounts", "GetRates"],
      // cache is not smart enough to automatically update the cache for us in this case
      onError: (e) => {
        alert("UPDATE_TRANSACTIONS_AND_INSERTS ... error: ", JSON.stringify(e));
      },
      onCompleted: async () => {
        //alert("UPDATE_TRANSACTIONS_AND_INSERTS ... done");
        if (data) refetch(); closeForm();
      }
  });
/*
  const [mutate_insert] = useMutation( INSERT_TRANSACTIONS, {
      variables: { id: transaction_id, objects },
      refetchQueries: ["Transactions", "GetRates"],
      // cache is not smart enough to automatically update the cache for us in this case
      onCompleted: () => { if (data) refetch(); closeForm() }
  });
*/

 useEffect(() => {
    if (action === "edit" && data) {
      form.setFieldsValue(data && data.transaction);
      setPerms(data.transaction.internal_reference? {disabled: true} : {});
      const type = data.transaction.amount < 0?'OUT':'INC';
      setTypeComputed(type);
      // 2023.05.30 evg request for incoming too
      // if (type == 'OUT') 
      setAddMyCompanyId(auth.company_id);
      //alert(JSON.stringify(data)+" : "+JSON.stringify(data.internal_reference? {disabled: true} : {}));
    }
  }, [data]);

  ////////////////////////////////////////////////////////////////////////////////
  const link_transaction_via_bot = async (values) => {

debugger;
    const auth_token = localStorage.getItem(AUTH_TOKEN);

    // POST request using fetch with error handling
    const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type' : 'application/json', 
          'authorization': `Bearer ${auth_token}` || "",
        },
        body: JSON.stringify({ 
          id_transaction: transaction_assignId,
          id_company:     values.initiator_id,
          user:           auth.login,
          no_fee:         returnNoFee?1:0,
          comments:       values.comments,
          tenant:         getUserTenant(),
        })
    };

    setWait(true);
    const answer = await fetch(process.env.REACT_APP_SERVER_URL + "/bot/link", requestOptions)
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && (data.message || data.error)) || response.status;
                // return Promise.reject({ error: error });
                return ({ error: error });
            }
            
            if (!data.result || !data.result.length || !('code' in data.result[0])) {
               console.log ('link_transaction_via_bot: bad backend answer');
               return ({ error: 'bad backend answer' });
            }
            // Promise.resolve (data);
            if (data.result[0].code !== 0)
               console.log ('bot say: '+ data.result[0].code +'-'+ data.result[0].err_text);

            // Add this refetch as in link_transaction_via_mutation() 
            // via useMutation.refetchQueries
            await client.refetchQueries({
              include: ["Transactions", "Accounts", "GetRates"],
            });

            return (data.result[0]);
        })
        .catch(error => {
            console.error ('bot_link: fetch error: ', error);
            //return Promise.reject({ error: error });
            return ({ error: error.toString() });
        }).
        finally(() => {
           setWait(false);
        });

debugger;
    return (answer);
}

  // from handleSubmit()
/*
  const link_transaction_via_mutation = (values, query_data) => {
        
        delete values.__typename;
          
        // update transaction (assing)
        var set_values = {
           type_transaction:  typeComputed,  //values.type_transaction,
           initiator_id:  values.initiator_id,
           comments: values.comments,
           linked_user: auth.login,
           linked_at: new Date(),
        };
        // due `disabled` 
        if (values.description) set_values.description = values.description;
       
        //alert("amount_fee: "+ query_data.amount*fee+" "+Number(query_data.amount)*Number(fee));
        // insert internal transactions with fee
        var com_values = fee && !returnNoFee ? [{
          ...set_values,
          id: uuidv4(),
          type_transaction:  'COM',
          link_id: transaction_assignId,
          amount: query_data.amount*fee*-1,
          currency: query_data.currency,
          description: 'Generated',
        }] : [];
        // generate second transaction 
        if (com_values.length == 1) {
           // copy object for modifications
           com_values.push({...com_values[0]});

           com_values[1].id = uuidv4();
           com_values[1].amount *= -1;
           com_values[1].initiator_id = auth.company_id; // MY_COMPANY 
        }

        // insert bot queue info
        const bot_objs = [{
         id_transaction: transaction_assignId,
         id_initiator:  values.initiator_id,
         payload: "web",
        }];

        var v = {
          id: transaction_assignId,
          obj_update: set_values,
          objs_insert: com_values,
          bot_queue: bot_objs,
        };

        //alert("mutation vars: "+JSON.stringify(v));
        mutate({ variables: v });  
  }
*/
  ////////////////////////////////////////////////////////////////////////////////

  const handleSubmit = (e, query_data) => {
    //alert(JSON.stringify(query_data));
    form
      .validateFields()
      .then(values => {
        // try to move all buissines logic to one place -> bot
        // link_transaction_via_mutation (values, query_data) 

        link_transaction_via_bot(values)
        .then((res) => {
           if (res?.code === 0) {
             if (data) refetch(); 
             closeForm();
           } else {
              console.log('link_transaction_via_bot() => ', res);
              const message = res?.error || res?.err_text;
              alert("Can`t link transaction. Please contact with your system administrator. message: " + (message ? message : JSON.stringify(res)));
           }
        })
        .catch((error) => {
           alert("Can`t link transaction. Please contact with your system administrator. exception: " + JSON.stringify(error));
        });
    
      })
      .catch(info => {
        console.log("Validate Failed:", info);
        //alert(info);
      });
  };
  const closeForm = () => {
    dispatch("hideForm");
    form.resetFields();
    setCompanyId();
    setAddMyCompanyId();
    setFee();
    setReturnNoFee(false);
  };
  ///////////////////////////////////////

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Modal
      title="Attach transaction"
      visible={component === "transaction_assign"}
      okText={wait?<Spin size="small"/>:"Submit"}
      onOk={(e) => handleSubmit(e, data.transaction)}
      onCancel={closeForm}
    >
    { !access && MESSAGE_NO_ACCESS ||
    <Form layout="vertical" form={form} name="order_form" disabled={wait} >
      <Form.Item
        label="Type"
        name="type_transaction"
        _rules={[{ required: true, message: "Please select transaction type" }]}
      >
        <TransactionTypes value_fixed={typeComputed}/>
      </Form.Item>
      <Form.Item
        label="Initiator"
        name="initiator_id"
        rules={[{ required: true, message: "Please select initiator" }]}
      >
        <CompanySelect role="transaction_initiator" my_company_id={addMyCompanyId} onChange={(v) =>  setCompanyId(v)} />
      </Form.Item>

      {typeComputed != 'OUT' && 
       <Checkbox 
             onChange={(e) => setReturnNoFee(e.target.checked) }
             checked={returnNoFee}>return
       </Checkbox>
       }

      {(typeComputed != 'OUT' && !returnNoFee)?
        <Form.Item name="fee">
        <CompanyFee company_id={companyId} onChange={(v) => { if (typeComputed != 'OUT') setFee(v)} } />
        </Form.Item>
      :
      <b>No commission</b>
      }
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Please enter description" }]}
      >
        <Input.TextArea rows={4}
          {...perms} 
        />
      </Form.Item>
      <Form.Item
        label="Comments"
        name="comments"
      >
        <Input />
      </Form.Item>
    </Form>
    }
    </Modal>
  );
};

const GET_TRANSACTION = gql`
  query TransactionyId($id: uuid!) {
    transaction: p_transactions_by_pk(id: $id) {
    id
    account_id
    amount
    currency
    description
    internal_reference
    type_transaction
    created_at
    initiator_id 
    beneficiary_id
    remitter_id
    link_id
    synchronized_at
    comments
    }
  }
`;

/*
const INSERT_TRANSACTIONS = gql`
  mutation CreateTransaction($objects: [p_transactions_insert_input!]! $bot_queue: [bot_queue_insert_input!]!) {
    insert_p_transactions(objects: $objects) {
      affected_rows
    }
    insert_bot_queue (objects: $bot_queue) {
      affected_rows
    }
  }
`;
*/

const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction (
    $id: uuid!,
    $objects: p_transactions_set_input!
  ) {
    update_p_transactions(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default TransactionAssignForm;
