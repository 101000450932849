import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { Link } from "react-router-dom";
import { Button, Menu, SubMenuProps, Typography, Divider, Select, Space } from "antd";
import { logout, logout_all, isAdmin, getUserTenant /*, setUserTenant*/ } from "../../auth/authHelper";
import { ApiButton } from "../ApiButton";
import { GetUserButton } from "../GetUserButton";
import { UserProfile, profile } from "../users/UserProfile";
import { UserOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";

// after update antd to latest 4.x 
// Warning: [antd: Menu] `children` will be removed in next major version. 
// Please use `items` instead.
//
// TODO: rewrite MenuItemEx

// hide
// TODO: add Menu.SubMenu and hide it if no one visible MenuItemEx
/*
const MenuItemEx = (props) => {
  const { roles, children, ...otherProps } = props;
  const { auth } = useStoreon("auth");
  return roles && (roles === "*" || roles.includes(auth.user_role)) ? 
    <Menu.Item {...otherProps}>{children}</Menu.Item>
   : 
    <></>
   ;
};
*/

// disable
// TODO: add Menu.SubMenu and disable it if no one active MenuItemEx
const MenuItemEx = (props) => {
  const { roles, children, ...otherProps } = props;
  const { auth } = useStoreon("auth");
  const disabled = !(roles && (roles === "*" || roles.includes(auth.user_role)));
  return <Menu.Item {...otherProps} disabled={disabled}>{children}</Menu.Item>
};

export const AuthNavBar = props => {
  //const { dispatch } = useStoreon();
  const { dispatch, auth, tab } = useStoreon("auth", "tab");
  const client = useApolloClient();
  const [ menuKey, setMenuKey ] = useState();
  //const [ tenant, setTenant ] = useState(getUserTenant()||(tab&&tab.tennat));
  const [ tenant, setTenant ] = useState(tab.tenat);
  
  useEffect(() => {
    var path = window.location.href.split('/');
    setMenuKey(path.pop());
    console.log("AuthNavBar.useEffect: MOUNT tab:["+tab.tenant+"], state:["+tenant+"]");
    //setTenant(getUserTenant()||(tab&&tab.tennat));
    if (tab.tenant) 
      setTenant(tab.tenant);
  }); 

  const handleMenuClick = e => {
    switch (e.key) {
      case "logout":
        logout(client);
        dispatch("setAuthData", { authenticated: false });
        break;
      case "logout-all":
        logout_all(client);
        dispatch("setAuthData", { authenticated: false });
        break;
      case "profile":
        dispatch("showForm", { component: "profile" })
        //showProfile();
        break;
      //default:
        //return null;
    }
    setMenuKey(e.key);
  };

  const onChangeTenantHandler = (v) => {
    // alert('onChangeTenantHandler: '+v);

///////////////////
///////////////////
    dispatch("setTabData", { ...tab, tenant: v,});
    // use only storeon 
    //setUserTenant(v);
    setTenant(v);

    client.resetStore().then(() => {
      console.log('onChangeTenantHandler: client.resetStore: DONE');
      //alert('onChangeTenantHandler: client.resetStore: DONE');

      // await client.refetchQueries({
/*
      client.refetchQueries({
         include: "all", // "active",
      }).then(() => {
        alert('onChangeTenantHandler: client.refetchQueries: DONE');
      });
*/
    });

/*
 * 
            if (getUserTenant() !== values.tenant) {
              //alert(`new tenant: client.clearStore: was [${getUserTenant()}] => [${values.tenant}]`);

debugger;
              // reset the cache without refetching active queries
              client.clearStore().then(() => {
                alert("client.clearStore: done");
                client.cache.reset();
                client.cache.evict({ id: "ROOT_QUERY"}); // , fieldName: "Companies" 
                client.cache.gc();

                client.queryManager.cache.reset();
                if (client.localstate?.cache) client.localstate.cache.reset();

              });
              setUserTenant(values.tenant);

              //alert(`new tenant: client.clearStore: now [${getUserTenant()}]`);
            }
*/

/////////////////////
///////////////////
  }

//    {(auth.user_role === 'admin') && 
//    {isAdmin() &&  ... not refreshed 
//    <Menu.Item key="profile" style={{ float: "right", color: 'blue', backgroundColor: 'black', cursor: 'default' }} disabled>

//  defaultValue={(tab && tab.tenant)||process.env.REACT_APP_DEFAULT_TENANT}
//        <Select size="small" style={{ width: 120 }} defaultValue={getUserTenant()||process.env.REACT_APP_DEFAULT_TENANT} onChange={onChangeTenantHandler}>

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      onClick={handleMenuClick}
      style={{ lineHeight: "44px" }}
      defaultSelectedKeys={[menuKey]}
      selectedKeys={[menuKey]}
    >
      <Space direction="horizontal">
      <Typography.Text style={{ whiteSpace: "nowrap", fontSize: "10px", color: "green" }} >{process.env.REACT_APP_NAME+"@"+process.env.REACT_APP_VERSION}{/*[{tab&&tab.tenant}]*/}</Typography.Text>
        <Select size="small" style={{ width: 120 }} value={tenant} onChange={onChangeTenantHandler}>
          <Select.Option key="florunner" value="florunner">FloRunner</Select.Option>
          <Select.Option key="primeflower" value="primeflower">PrimeFlowers</Select.Option>
        </Select>      
      </Space>

      <Menu.SubMenu key="logistics" title="Logistics" popupOffset="0" >  
        <MenuItemEx key="planning" roles={['admin', 'shipper']}>
          <Link to="/planning">Planning</Link>
        </MenuItemEx>
        <MenuItemEx key="trucks" roles={['admin']}>
          <Link to="/trucks">Trucks</Link>
        </MenuItemEx>
        <MenuItemEx key="orders"  roles={['admin', 'shipper', 'supplier']}>
          <Link to="/orders">Orders</Link>
        </MenuItemEx>
        <MenuItemEx key="shipments"  roles={['admin', 'shipper']}>
          <Link to="/shipments">Shipments</Link>
        </MenuItemEx>
      </Menu.SubMenu>

      <MenuItemEx key="users" roles={['admin']}>
        <Link to="/users">Users</Link>
      </MenuItemEx>
      <MenuItemEx key="companies" roles={['admin', 'shipper', 'supplier']} >
        <Link to="/companies">Companies</Link>
      </MenuItemEx>

      <Menu.SubMenu key="finance" title="Finance" roles={['admin']} popupOffset="0" >
        <MenuItemEx key="accounts" roles={['admin']}>
          <Link to="/finance/accounts">Accounts</Link>
        </MenuItemEx>
  
        <Menu.SubMenu key="transactions" title="Transactions" popupOffset="0" >  
           <MenuItemEx key="transactions_all" roles={['admin']}>
             <Link to="/finance/transactions">All</Link>
           </MenuItemEx>
           <MenuItemEx key="transactions_inc" roles={['admin']}>
             <Link to="/finance/transactions_inc">Incoming</Link>
           </MenuItemEx>
           <MenuItemEx key="transactions_out" roles={['admin']}>
             <Link to="/finance/transactions_out">Outgoing</Link>
           </MenuItemEx>
           <MenuItemEx key="transactions_local" roles={['admin']}>
             <Link to="/finance/transactions_local">Local</Link>
           </MenuItemEx>
        </Menu.SubMenu>

       <Menu.Divider/>

        <MenuItemEx key="company_cfg" roles={['admin']}>
          <Link to="/finance/transactions_config">Transactions autolink rules</Link>
        </MenuItemEx>
        <MenuItemEx key="spreads_cfg" roles={['admin']}>
          <Link to="/finance/exchange_config">Currency exchange spreads</Link>
        </MenuItemEx>
        <Menu.SubMenu key="certificates" title="Certificates" popupOffset="0" >  
          <MenuItemEx key="certificates_add" roles={['admin', 'certificate_manager']}>
            <Link to="/finance/certificates/add">Add certificates</Link>
          </MenuItemEx>
          <MenuItemEx key="certificates_view" roles={['admin', 'certificate_manager']}>
            <Link to="/finance/certificates/view">Certificates storage</Link>
          </MenuItemEx>
        </Menu.SubMenu>
       <Menu.Divider/>

        <MenuItemEx key="charts" roles={['admin']}>
          <Link to="/finance/charts">Charts</Link>
        </MenuItemEx>
        <MenuItemEx key="charts_crt" roles={['admin']}>
          <Link to="/finance/charts_crt">Charts CRT</Link>
        </MenuItemEx>

       <Menu.Divider/>

        <MenuItemEx key="gocardless" roles={['admin']} disabled={false}>
          <Link to="/finance/gocardless">Gocardless</Link>
        </MenuItemEx>
        <MenuItemEx key="raiffeisen" roles={['admin']} disabled={true}>
          <Link to="/finance/raiffeisen">Raiffeisen</Link>
        </MenuItemEx>
      </Menu.SubMenu>

      <MenuItemEx key="settings" roles={['admin']}>
        <Link to="/settings">Settings</Link>
      </MenuItemEx>

      <MenuItemEx key="test" roles={['admin']}>
        <Link to="/test">TestPage</Link>
      </MenuItemEx>

      <Menu.Item key="profile" style={{ marginLeft: 'auto' }} >
        <Button type="link" ><UserOutlined/> [{auth.login}]</Button>
      </Menu.Item>

      <MenuItemEx key="api_test" roles={['admin']}>
        <ApiButton />
      </MenuItemEx>
      <MenuItemEx key="get_user" roles={['admin']}>
        <GetUserButton />
      </MenuItemEx>
      <MenuItemEx key="logout-all" roles={['admin']}>
	<Link to="/logout">Logout all</Link>
      </MenuItemEx>
{/*
      <Menu.Item key="logout" style={{ float: "right" }}>
	<Link to="/logout">Logout</Link>
      </Menu.Item>

      <Menu.Item key="version" disabled={true} >
      {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
      </Menu.Item>

      <Menu.ItemGroup size="small" title={process.env.REACT_APP_NAME+" "+process.env.REACT_APP_VERSION} />

*/}      
    </Menu>
  );
};
