import React, { Component, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useStoreon } from "storeon/react";

import { Form, Input, Modal, Drawer, Alert, Button } from "antd";
import { Col, Row, Statistic, Collapse, Table } from 'antd';

import ConsentCreate from "./ConsentCreate";
import ConsentStatus from "./ConsentStatus";

import { MESSAGE_NO_ACCESS } from "../../../auth/authHelper";

/////////////////////////////////////////////////////
const GET_BANK_PARAMETER = gql`
query GET_BANK_PARAMETER ($par_name: String!) {
  bank_parameters(
    where: {_and: [
      {bank_name: {_eq: "RBHU"}}, 
      {par_name: {_eq: $par_name}}]
    }) {
    par_value
  }
}`;

/////////////////////////////////////////////////////
const ConsentCreateButton = ({state, fn:setState, ...otherProps}) => {

  const onClickHandler = async () => {
    setState({...state, status: 'create'});
    //alert ('onClickHandler: fired');
  }  
  return (
     [<Button
        type="dashed"
        loading={state.stage === 'create'}
        style={{ marginBottom: 16 }}
        onClick={() => { onClickHandler(); }}
        disabled={state.stage !== 'nope'? true: false}
     >
      Create new consent
     </Button>]
  );
}

/////////////////////////////////////////////////////
const RaiffeisenPage = (props) =>  {
  const { auth } = useStoreon("auth");
  const access = (auth.user_role == "admin");
  const [state, setState] = useState({ status: 'unknown', stage:'nope', consent_id: null});

  console.log("RaiffeisenPage: (CONSENTS) props: ", props);

  // get from db last saved consent id 
  const { loading, error, data } = useQuery(GET_BANK_PARAMETER, { skip: !access, variables: {par_name: "consent_id"}});

  useEffect(() => {
      if (data) console.log("RaiffeisenPage: useEffect: data:", JSON.stringify(data));
      const consent_id = (data?.bank_parameters.length > 0)? data.bank_parameters[0].par_value : null;
      setState({...state, status: 'fetched', consent_id: consent_id});
  }, [data]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  if (!access) return MESSAGE_NO_ACCESS;

  console.log(JSON.stringify(data));

  return <div>
           [{state?.status}]<br/>
           <ConsentCreateButton state={state} fn={setState} />
           <ConsentCreate state={state} fn={setState} />
           { state.consent_id && <ConsentStatus id={state.consent_id} /> }
        </div>;
};

export default RaiffeisenPage;
