import React, { useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { Input, Tag, Space, Table } from "antd";

import TransactionsRulesTable from "./TransactionsRulesTable";
import { MESSAGE_NO_ACCESS } from "../../auth/authHelper";

const columns = [
  {
    title: "Name",
    //dataIndex: "name",
    render: (rec) =>
<>
<Space>    
{rec.name}
{rec.rules?.aggregate?.count > 0 &&
 <Tag color="#2db7f5">Rules {rec.rules?.aggregate?.count}</Tag>
}
</Space>
</>
  },
];

///
const CompaniesConfigTable = () => {
  const { auth, dispatch } = useStoreon("auth", "selection");
  const access = (auth.user_role == "admin");

  const { loading, error, data } = useQuery(GET_COMPANIES, {skip: !access });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  if (!access) return MESSAGE_NO_ACCESS;

  return (
    <div>
      <Row>
        <Col span={24}>
          <Table
            pagination={data?.companies?.length > 1}
            rowKey="id"
            columns={columns}
            //dataSource={data && data.companies}
            dataSource={data.companies}
            size="small"
            _rowClassName={(record) => record.active ? '' : 'not-active-row' }
            onExpand={(_, r) => {
              //dispatch("select", { model: "company", id: r.id });
              //alert(JSON.stringify(r));
            }}
            expandedRowRender={record => (
	      <TransactionsRulesTable company_id={record.id} />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
// companies_view?
const GET_COMPANIES = gql`
  query Companies {
    companies: companies (
	order_by: [{ name: asc }]
    ) {
      id
      name
      short_name
      rules: transactions_rules_aggregate {  aggregate { count } }
    }
  }
`;

export default CompaniesConfigTable;

/*
                  <FormBtn component="company" action="edit" />
                  <DeleteCompanyBtn id={record.id} 
			//style={{ float: "right" }}
		{...perms}/>
                  <OperCompanyBtn operation="active" id={record.id} defaultChecked={record.active} active={record.active} {...perms}/>
*/
