import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Select, Space, Tag } from 'antd';
import { css } from '@emotion/css';
import moment from "moment";
import { useStoreon } from "storeon/react";

import { isAdmin } from "../../auth/authHelper";
import InvoiceForm from './InvoiceForm';  

import { currency_formatters, invoice_status_decode, invoice_status_style } from './InvoiceConstants'; 

const tableCSS = css({
  margin: '5px 5px',
  backgroundColor: 'white',

  '& table': {
    borderCollapse: 'collapse'
  },

  '& thead > tr > th': {
    backgroundColor: 'rgb(41, 138, 171)',
    color: 'white',
  },

  '& thead > tr': {
    borderWidth: '1px',
    borderColor: 'rgb(229, 231, 235)',
    borderStyle: 'solid'
  }
});

/*
const tagCSS = css({
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.badge-success {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity));
}
.badge-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.badge-warning {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity));
}
.badge {
    display: inline-flex;
    align-items: center;
    border-radius: 9999px;
    padding: .125rem .625rem;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1rem;
}
});
*/

const API_INVOICE = process.env.REACT_APP_SERVER_URL + '/invoiceninja/invoices';

const get_client_by_id = (clients, id) => {
  return Array.isArray(clients) ? clients.find((record) => record.id == id) : null;
}

////////////////////////////////
const InvoiceTable = ({clients, client_idx: p_client_idx }) => {
  const { auth } = useStoreon("auth");
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('name|asc');
  const [currency, setCurrency] = useState();
  const [invoiceId, setInvoiceId] = useState();

  console.log('InvoiceTable: begin, p_client_idx:', p_client_idx, " id=", clients[p_client_idx]?.id);

  const [pagination, setPagination] = useState({
    showSizeChanger: false,
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal_: (total) => `Total ${total} invoices`,
    showTotal__: (total, range) => `Showing ${range[0]} to ${range[1]} out of ${total} invoices`,
    itemRender_: (_, type, originalElement) => {
      if (type === 'prev') return <a>&laquo;Previous</a>;
      else if (type === 'next') return <a>Next&raquo; </a>;
      return originalElement;
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function for downloading data from the server
  const fetchInvoices = async (currentPage, perPage) => {
    setIsLoading(true);
    try {
      // const client_id = p_client_id ? p_client_id : clients.map((c) => c.id).reduce((accumulator, currentValue) => currentValue+'|'+accumulator, '');
      // alert(JSON.stringify(clients));
      // alert(JSON.stringify(client_id));
      // just use only one client in opertor mode (god mode reserved for future )

      const client_id = clients[p_client_idx]?.id;
      if (!client_id) throw new Error('Missed client id');
alert(p_client_idx+' => '+client_id);
 
      const url = `${API_INVOICE}?page=${currentPage}&per_page=${perPage}&client_id=${client_id}&sort=${sort}`;
      console.log('fetchInvoices: fetch:', url);
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const { data: jsonResponse } = await response.json();
      if (!jsonResponse?.data || !jsonResponse?.meta?.pagination) throw new Error('Bad response structure');

      setData(jsonResponse.data);
      setPagination({ ...pagination, total: jsonResponse.meta.pagination.total });

    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Loading data on first render and page change
  useEffect(() => {
    if (!clients?.length && !p_client_idx && !clients[p_client_idx]) return;

    fetchInvoices(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, p_client_idx]);

  // 
  const handleTableChange = (newPagination) => {
    alert('handleTableChange')
    setPagination(newPagination);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Client Name',
      // if we will save the results of searching for invoices and combine them into one array with clients data
      // dataIndex: ['client', 'name'],
      key: 'client_name',
      render: (_, record) => (get_client_by_id(clients, record.client_id)||{}).display_name,
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'PO NUMBER',
      dataIndex: 'po_number',
      key: 'po_number',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => currency_formatters[clients[p_client_idx].settings.currency_id].format(value),
    },
    {
      title: 'INVOICE DATE',
      dataIndex: 'date',
      key: 'date',
      render: (value) => moment(value).format('DD/MMM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status_id',
      key: 'status_id',
      render: (value) => <Tag style={invoice_status_style[value]}>{invoice_status_decode[value]}</Tag>,
      render_: (value) => <span style={{color: invoice_status_style[value].color}}>{invoice_status_decode[value]}</span>,
    },
    {
      key: 'edit',
      dataIndex: 'id',
      render: (value) => <Button type="link" onClick={() => { setInvoiceId(value); showModal(); } }>Edit</Button>,
    },
  ];

  // Открытие модального окна для создания нового инвойса
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Закрытие модального окна
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Обработка успешного создания инвойса
  const handleSuccess = () => {
    message.success('Новый инвойс успешно создан!');
    setIsModalVisible(false);
    fetchInvoices(pagination.current, pagination.pageSize); // Перезагрузить данные после создания нового инвойса
  };

  //
  /*
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
        // Table.SELECTION_ALL,Table.SELECTION_INVERT, Table.SELECTION_NONE,
        {
          key: 'data-all',
          text: 'Select All Data',
          onSelect: changableRowKeys => {
            onSelectChange(filteredData.map((a) => a.id));
          },
        },
        {
          key: 'data-none',
          text: 'Clear All Data',
          onSelect: changableRowKeys => {
            onSelectChange([]);
            //setSelectedRowKeys([]);
          },
        },
    ],
    hideDefaultSelections: true,
  };
  */

  //
  if (!data) return ;

  return (
    <>
      <Button disabled={isLoading} type="primary" onClick={showModal} style={{ marginBottom: 16, backgroundColor_: 'rgb(41, 138, 171)'}} >
        Create new invoice
      </Button>
      <Space type="horizontal">
      <span style={{ marginLeft: '10px', }}> Per Page: </span>
      <Select
        disabled={isLoading}
        defaultValue="10"
        style={{ width: 120, }}
        onChange={(e) => { alert('Select.onChange'); setPagination({ ...pagination, pageSize: e });  }}
        options={[
          { value: '5',label: '5', },
          { value: '10',label: '10', },
          { value: '15',label: '15', },
          { value: '20',label: '20', },
        ]}
      />
      </Space>
      <Table
        rowKey="id"
        dataSource={data}
        className={tableCSS}
        columns={columns}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => handleTableChange({ current: page, pageSize }),
        }}
        loading={isLoading}
      />
      <Modal
        title="Create new invoice"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <InvoiceForm invoice_id={invoiceId} onSuccess={handleSuccess} />
      </Modal>
    </>
  );
};

export default InvoiceTable;
