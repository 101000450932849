import React, { useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Input, Table, Row, Col } from "antd";
import FormBtn from "../shared/FormBtn";
import ChangePasswordBtn from "./ChangePasswordBtn";
import UserDeleteBtn from "./UserDeleteBtn";
import { FilesIcon } from "../shared/FilesIcon";
import { Redirect } from "react-router-dom";
import { isSupplier, isAdmin, MESSAGE_NO_ACCESS } from "../../auth/authHelper";

const columns = [
  {
    title: "Role",
    dataIndex: "default_role",
  },
  {
    title: "Files",
    width: "3%",
    render: record => <FilesIcon id={record.id} dataModel="user" count={record.files.aggregate.count}/>
  },
  {
    title: "Email",
    dataIndex: "email",
    render: text => <a href={"mailto:"+text}>{text}</a>,
  },
  {
    title: "Name",
    dataIndex: "display_name",
    sorter: (a, b) => {
      let nameA = a.display_name?a.display_name.toUpperCase():"";
      let nameB = b.display_name?b.display_name.toUpperCase():"";
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    defaultSortOrder: "ascend",
  },
  {
    title: "First Name",
    dataIndex: "first_name",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
  },
  {
    title: "Job title",
    dataIndex: "job_title",
  },
];

const columns_all = [
  {
    title: "Company",
    dataIndex: ["company", "name"],
  },
  {
    title: "Short name",
    dataIndex: ["company","short_name"],
    //dataIndex: ["company", "short_name"],
    //key: 'company.short_name',
    //render: (record) => record && record.company && record.company.short_name,
  },
  {
    title: "City",
    //dataIndex: "company.city",
    render: (record) => record && record.company && record.company.city
  },
];

const UsersTable = (props) => {
 const [filter, setFilter] = useState('');
 const { auth, dispatch } = useStoreon("auth");
 const access = (auth.user_role == "admin");

//  const { selection } = useStoreon("selection");
//  const companyId = selection.companyId;

  const companyId = props.company_id;
  // now we can use only company_id without props.mode
  const all = (props && props.mode === "all");
  const perms = !isAdmin()? {disabled: true} : {};
  const service_column =
  {
    title: "",
    render: (record) => (
      <div style={{ float: "right" }}>
        <FormBtn component="user" action="edit" id={record.id} company_id={record.company_id}/>
        <ChangePasswordBtn id={record.id} {...perms} />
        <UserDeleteBtn id={record.id} type="danger" {...perms} />
      </div>
    ),
  };

  const columns_ = all?[...columns, ...columns_all, service_column]:[...columns, service_column];

  const { loading, error, data } = useQuery(companyId? GET_USERS: GET_ALL_USERS, {
//    skip: !companyId,
    skip: !access,
    variables: companyId? { id: companyId } : {},
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  if (!access) return MESSAGE_NO_ACCESS;


/*
 * just for test
 * 
  if (error) 
     return <Redirect 
       to={{
         pathname: "/login",
         state: { mode: 'clear' }
       }}
     />;
*/

  const filteredData = filter
     ? data.users.filter((user) =>
        user
        ? user.display_name?.toLowerCase().includes(filter.toLowerCase())
        || user.first_name?.toLowerCase().includes(filter.toLowerCase())
        || user.last_name?.toLowerCase().includes(filter.toLowerCase())
        : false,
     )
     :data.users;

  const doSearch = (value) => {
        setFilter(value);
        // refetch();
  };

  console.log("UserTable: got result: ", data); 

  return (
    <div>
     <Input.Group>
      <Row gutter={8}>
{/*
  * create users at companies tab or as truck driver
  */
   companyId && //isAdmin() &&
        <Col span={1}>
      <FormBtn component="user" company_id={companyId} action="add" {...perms}/>
        </Col>
}
        <Col span={4}>
      <Input
            maxLenght="40"
            placeholder="search by name ..."
            value={filter}
            _enterButton
            _onSearch={doSearch}
            onChange={(event) => doSearch(event.target.value)}
      />

        </Col>
      </Row>
     </Input.Group>

      <Table
        pagination={filteredData?.length > 1}
        rowKey="id"
        columns={columns_}
        _dataSource={data && data.users}
        dataSource={filteredData}
        style={{ margin: 0 }}
        size="small"
      />
    </div>
  );
};

const GET_USERS = gql`
  query Users($id: Int) {
    users(
      where: { company_id: { _eq: $id } },
      order_by: [{ last_name: asc }]
    ) {
      id
      email
      default_role
      first_name
      last_name
      display_name
      job_title
      company_id
      company {
        name
        short_name
        city
      }
      contacts {
        id
        contact
        description
        user_id
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

// same as GET_USERS except WHERE
// due new HASURA behavior

const GET_ALL_USERS = gql`
  query Users($id: Int) {
    users(
      order_by: [{ last_name: asc }]
    ) {
      id
      email
      default_role
      first_name
      last_name
      display_name
      job_title
      company_id
      company {
        name
        short_name
        city
      }
      contacts {
        id
        contact
        description
        user_id
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;
 
export default UsersTable;
