import React from "react";
import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import { ApiButton } from "../ApiButton";
import { useStoreon } from "storeon/react";

export const NavBar = () => {
  const { auth, tab } = useStoreon("auth", "tab");

  return (
    // overheaded
    auth.authenticated ? <></> : 

    <Menu theme="dark" mode="horizontal" style={{ lineHeight: "44px" }}>
      <Typography.Text style={{ whiteSpace: "nowrap", fontSize: "10px", color: "green" }} >{process.env.REACT_APP_NAME+"@"+process.env.REACT_APP_VERSION}</Typography.Text>

      <Menu.Item key="home">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="about">
        <Link to="/about">About</Link>
      </Menu.Item>
      <Menu.Item key="api_test" style={{ marginLeft: 'auto' }}>
        <ApiButton />
      </Menu.Item>
      <Menu.Item key="login" >
        <Link to="/login">Login</Link>
      </Menu.Item>
    </Menu>
  );
};
