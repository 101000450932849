import React, { useEffect, useState, useMemo } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { message, Form, Input, Modal, Button } from "antd";
import { Empty, Space, Drawer, List, Avatar, Divider, Col, Row } from 'antd';
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useStoreon } from "storeon/react";
import Address from "../shared/Address";
import { logout, logout_all } from "../../auth/authHelper";
import { useApolloClient } from "@apollo/client";

const DescriptionItem = ({ title, content }) => (
  <div> <b>{title}:</b> {content} </div>
);

const UserProfile = () => {
  const [ form ] = Form.useForm();
  const { dispatch, ui, auth } = useStoreon("ui", "auth");
  const { action, component } = ui.visibleForm;
//  const user = useMemo(() => auth.user_id, [auth.user_id]);
  const user = auth.login;

  const client = useApolloClient();
  console.log("UserProfile: user=", user, ", auth=", auth);

  const onClose = (d) => {
    dispatch("hideForm", { component: "profile" })
  };

  const { loading, error, data } = useQuery(GET_USER_INFO, {
    skip: !user || component !== "profile",
    variables: {
      username: user
    }
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const clickLogout = () => {
        logout(client);
        dispatch("setAuthData", { authenticated: false });
        dispatch("hideForm", { component: "profile" })
  }
  const clickLogoutAll = () => {
        logout_all(client);
        dispatch("setAuthData", { authenticated: false });
        dispatch("hideForm", { component: "profile" })
  }

  const profile = data?.account[0];
  return (
//////////////////////////////

//         title={`[${(tab && tab.tenant)||process.env.REACT_APP_DEFAULT_TENANT}]`} 

<Drawer
          width={440}
          placement="right"
          closable={true}
          onClose={onClose}
          visible={component === "profile"}
        >
          <Row>
            <Col span={24}>
            <Space>
            <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
            User Profile
            <Link to="/logout" onClick={clickLogout} style={{cursor: 'pointer'}}>[Logout]</Link>
            <Link to="/logout" onClick={clickLogoutAll} style={{cursor: 'pointer'}}>[Logout all]</Link>
            </Space>
            </Col>
          </Row>
          <Divider orientation="left">Personal</Divider>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Full Name" content={profile?.user.first_name+' '+profile?.user.last_name} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Account" content={profile?.username} />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Display name" content={profile?.user.display_name} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Roles" content={
               profile?.user.default_role+(profile?.user.roles?.length>0?', '+profile?.user.roles.join():'')
               } />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Email" content={<a href={"mailto:"+profile?.user.email}>{profile?.user.email}</a>} />
            </Col>
          </Row>

 {profile?.user.contacts?.length > 0 &&
          <Row>
            <Col span={24}>
              <Divider />
                <p className="site-description-item-profile-p">Contacts</p>
 {profile?.user.contacts.map((f, i) => <DescriptionItem key={i} title={f.description} content={f.contact} />)}
            </Col>
          </Row>
 }
          <Divider orientation="left">Company</Divider>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Name" content={profile?.user.company.name} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Short Name" content={profile?.user.company.short_name} />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Role" content={profile?.user.company.role} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Description" content={profile?.user.company.description} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Address" content={
                 <>
                 <Address record={profile?.user.company} />
                 {profile?.user.company.country?.name}
                 </>
              } />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">Contacts</p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Email" content={profile?.user.company.email} />
            </Col>
            <Col span={12}>
              
            </Col>
          </Row>
        </Drawer>
    );
  }

export default UserProfile;

const GET_USER_INFO = gql`
 query GetUserInfo($username: String!) {
 account: user_accounts(where: { username: { _eq: $username } }) {
    username
    created_at
    updated_at
    user {
      id
      active
      default_role
      user_roles {
        role
      }
      display_name
      first_name
      last_name
      contacts {
        contact
        description
      }
      email
      job_title
      company {
        name
        short_name
        description
        role
        city
        street
        house_no
        country {
          name
        } 
        email
      }
    }
  }
}
`;
