import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Modal, Input, Table, Row, Col } from "antd";
import { FilesIcon } from "../shared/FilesIcon";
import { isAdmin, MESSAGE_NO_ACCESS } from "../../auth/authHelper";

import gql from "graphql-tag";
import moment from "moment";

const columns = [
  {
    title: "Owner",
    width: "3%",
    render: record => get_file_owner(record),
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Size",
    dataIndex: "size",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Key",
    dataIndex: "key",
    render: key => <Link
      to={{ pathname: process.env.REACT_APP_SERVER_URL + "/storage/download/" + key}}
      target="_blank"
      download
      >{key}</Link>
  },
  {
    title: "Created",
    width: "3%",
    dataIndex: "created_at",
    render: date => {
       return {
            props: { style: { whiteSpace: "nowrap" } },
            children: <>{moment(date).format("DD-MM-YYYY")} </>
          };
    },
    sorter: (a, b) => {
      if (a.created_at < b.created_at) { return -1; } else if (a.created_at > b.created_at) { return 1; }
      return 0;
    },
  },
  {
    title: "Modified",
    width: "3%",
    dataIndex: "last_modified",
    render: date => {
       return {
            props: { style: { whiteSpace: "nowrap" } },
            children: <>{moment(date).format("DD-MM-YYYY")} </>
          };
    },
    sorter: (a, b) => {
      if (a.last_modified < b.last_modified) { return -1; } else if (a.last_modified > b.last_modified) { return 1; }
      return 0;
    },
  },
];

const get_file_owner = (record) => {
  const key = Object.keys(record).filter((k)=> k.substr(-3) === "_id" && record[k]);
  return (!key || key.length !== 1)? "unknown" : key[0].substr(0, key[0].length-3);
};

const ModalContent = ()  => {
}

const DeletedFilesTable = (props) => {
  const [filter, setFilter] = useState('');
  const { auth } = useStoreon("auth");
  const { ui, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //const [isModified, setIsModified] = useState(false);

  const { loading, error, data } = useQuery(GET_DELETED_FILES, {
    variables: {},
  });

  if (!isAdmin()) return <>You have not permissions</>;

  //if (loading) return "Loading...";
  //if (error) return `Error! ${error.message}`;

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const closeForm = () => {
    setSelectedRowKeys([]);
    dispatch("hideForm");
  };

  const handleSubmit = () => {
    console.log("handleSubmit: ");
  };

  return (
      <Modal
        maskClosable={false}
        width="1000px"
        title="Deleted (hidden) files"
        visible={component === "deleted_files"}
        onOk={handleSubmit}
        okText="Expunge"
        okButtonProps={{disabled: !selectedRowKeys.length }}
        onCancel={closeForm}
        cancelButtonProps={{disabled:false}}
      >
      {loading && "Loading..."}
      {error && `Error! ${error.message}`}
      {!loading&&!error &&
      <Table
        pagination={true}
        rowSelection={rowSelection}
        rowKey="id"
        columns={columns}
        dataSource={data && data.files}
        style={{ margin: 0 }}
        size="small"
      />
      } 
    </Modal>
  );
};


const GET_DELETED_FILES = gql`
  query Files {
    files (
      where: { hidden: { _eq: true }}
      order_by: [{ last_modified: asc }]
    ) {
      id
      key
      name
      size
      created_at
      last_modified
      type
      order_id
      shipment_id
      user_id
      company_id
      truck_id
      trailer_id
      }
    }
`;
 
export default DeletedFilesTable;
