import React from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const { Option } = Select;

const CompanySelect = props => {
  const { role, role_text, my_company_id, value, ...otherProps } = props;

  const objects = {
      role: role
  };
  if (my_company_id) objects.plus_id = my_company_id;

  //alert(JSON.stringify(objects));
  const { loading, error, data } = useQuery(my_company_id?GET_COMPANIES_PLUS:GET_COMPANIES, {
    fetchPolicy: "network-only",
    variables: objects
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Select
      {...otherProps}
      allowClear
      value={value && data.companies.filter(i => i.id === value)[0]?.name}
      showSearch
      placeholder={"Select "+(role_text??role??"company")}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
/*
 * TODO: not worked yet
 *
      filterSort={(optionA, optionB) => {
       alert(JSON.stringify(optionA)+" : "+JSON.stringify(optionB));
       if (optionA?.key == my_company_id) return -1;
       if (optionB?.key == my_company_id) return 1;
       return 0;
      }}
*/
    >
      {data.companies.map((item, key) => {
        return (
          <Option key={key} value={item.id} style={item.id===my_company_id?{backgroundColor: "#91c2eb"}:{}}>
            {item.name}
          </Option>
        );
      })}
    </Select>
  );
};

// due to permisions
const GET_COMPANIES = gql`
  query GetCompanies($role: String!) {
    companies (where: { role: { _eq: $role } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

const GET_COMPANIES_PLUS = gql`
  query GetCompanies($role: String! $plus_id: Int!) {
    companies (where: { 
     _or: [
      {id: {_eq: $plus_id}}
      {role: {_eq: $role}}
     ]
     },
     order_by: { name: asc }
    ){
      id
      name
    }
  }
`;

export default CompanySelect;
