import React from "react";
import ShipmenForm from "./components/shipments/ShipmentForm";
import OrderForm from "./components/orders/OrderForm";
import UploadForm from "./components/shared/UploadForm";
import ShipmentFilterForm from "./components/shipments/ShipmentFilterForm";
import CompanyForm from "./components/companies/CompanyForm";
import TruckForm from "./components/trucks/TruckForm";
import TrailerForm from "./components/trailers/TrailerForm";
import TrailerConnectForm from "./components/trailers/TrailerConnectForm";
import UserForm from "./components/users/UserForm";
import UserProfile from "./components/users/UserProfile";
import UserChangePassword from "./components/users/UserChangePassForm";
import WarehouseForm from "./components/warehouses/WarehouseForm";
import OrderImportForm from "./components/orders/OrderImportForm";
import CountryLookupCodes from "./components/orders/CountryLookupForm";
import ShipmentStatusCodes from "./components/settings/ShipmentStatusCodes";
import BotEmailTemplate from "./components/settings/BotEmailTemplate";
import ShipmentStatusForm from "./components/shipments/ShipmentStatusForm";
import TransactionForm from "./components/finance/TransactionForm";
import TransactionAssignForm from "./components/finance/TransactionAssignForm";

export const FormContainer = () => {
  return (
    <>
      <ShipmenForm />
      <OrderForm />
      <OrderImportForm />
      <UploadForm />
      <CompanyForm />
      {/*<ShipmentFilterForm />*/}
      <TruckForm />
      <TrailerForm />
      <TrailerConnectForm />
      <UserForm />
      <UserChangePassword />
      <WarehouseForm />
      <CountryLookupCodes />
      <UserProfile />
      <ShipmentStatusCodes />
      <BotEmailTemplate />
      <ShipmentStatusForm />
      <TransactionForm />
      <TransactionAssignForm />
    </>
  );
};

