import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { Link } from "react-router-dom";
import { Button, Menu, SubMenuProps, Typography, Divider, Select, Space } from "antd";
import { logout, logout_all, isAdmin, getUserTenant, getUserRole /*, setUserTenant*/ } from "../../auth/authHelper";
import { ApiButton } from "../ApiButton";
import { GetUserButton } from "../GetUserButton";
import { UserProfile, profile } from "../users/UserProfile";
import { UserOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";

import { MenuFlora } from "./MenuFlora";
import { MenuInvoice } from "./MenuInvoice";

export const AuthNavBar = props => {
  const { auth, tab } = useStoreon("auth", "tab");
  const [ role, setRole ] = useState();

  useEffect(() => {
    var path = window.location.href.split('/');
    console.log("AuthNavBar.useEffect: MOUNT ");
  }); 

  useEffect(() => {
    console.log("AuthNavBar.useEffect: auth: ", auth.user_role);
    setRole (auth?.authenticated ? auth.user_role : null); // getUserRole() - empty at first call
  }, [auth]); 

  return !role ? <></> : (role == 'invoice_manager' ? <MenuInvoice/> : <MenuFlora/>);
};
