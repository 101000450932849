import React, { Component, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { PlusOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useStoreon } from "storeon/react";
import { Form, Input, Modal, Drawer, Alert, Button, Tooltip } from "antd";
import { Space, Col, Row, Statistic, Collapse, Table } from 'antd';

//import styled from 'styled-component';
import "./GocardlessPage.css";

import moment from "moment";

import useFetch from "../utils/utils";

import LinkDeleteBtn from "./LinkDeleteBtn";
import LinkApproveBtn from "./LinkApproveBtn";
import LinkRenewBtn from "./LinkRenewBtn";

import GetInstitution from "./GetInstitution";

import { MESSAGE_NO_ACCESS } from "../../../auth/authHelper";

const REQUISITION_STATUSES = [ 
[ 'CR', 'CREATED', 'Requisition has been successfully created', 1 ],
[ 'GC', 'GIVING_CONSENT', 'End-user is giving consent at GoCardless\'s consent screen', 2],
[ 'UA', 'UNDERGOING_AUTHENTICATION', 'End-user is redirected to the financial institution for authentication', 3 ],
[ 'RJ', 'REJECTED', 'Either SSN verification has failed or end-user has entered incorrect credentials', 4 ],
[ 'SA', 'SELECTING_ACCOUNTS', 'End-user is selecting accounts', 5 ],
[ 'GA', 'GRANTING_ACCESS', 'End-user is granting access to their account information', 6 ],
[ 'LN', 'LINKED', 'Account has been successfully linked to requisition', 7 ],
[ 'EX', 'EXPIRED', 'Access to accounts has expired as set in End User Agreement', 8 ]
];

/////////////////////////////////////////////////////
const GET_GOCARDLESS_PARAMETERS = gql`
query Gocardless_parameters {
  bank_parameters (where: { bank_name: {_eq: "gocardless"}}) {
    par_name
    par_value
  }
  links: bank_parameters( where: {par_name: {_eq: "req_id"}}) {
    bank_name
    link_id: par_value
    updated_at
  }
}`;

/////////////////////////////////////////////////////
const columns_db = [
  {
    title: 'Bank',
    dataIndex: 'bank_name', //['links', 'bank_name'],
    key: 'bank_name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Link',
    dataIndex: 'link_id',
    key: 'link_id',
  },
  {
    title: 'Modified',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: date => <>{moment(date).format("DD.MM.YYYY HH:mm:ss")} </>,
  },
];

/////////////////////////////////////////////////////
const GocardlessPage = (props) =>  {
  const { auth } = useStoreon("auth");
  const access = (auth.user_role == "admin");

  const api_url = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/links";
  const [state, setState] = useState({});
  const [createState, setCreateState] = useState({loading: false});
  const [preparing, setPreparing] = useState(false);

  // for modal 
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component, role } = ui.visibleForm;

  // update => relaod (need refetch data) 
  //const [, updateState] = React.useState();
  //const forceUpdate = React.useCallback(() => updateState({}), []);

  //const [tabData, setTabData] = useState([]);
  /*
  const forceReload_ = React.useCallback(() => {
     //console.log('forceReload:', reload);
      setState({});
      if (refetch) refetch();
      return setReload(!reload);
   }, 
  []);
  */

  const forceReload = () => {
      setState({});
 
      if (refetch) refetch();
      if (linksReload) linksReload();

   };

  ///////////////////////////////////////////////////
  const removeHandler = (p_id) => {

// something wrong ...
// may be reload all page

/*
    const new_data = { ...state.linksDataEx };
    new_data.results = new_data.results.filter((r) => r.id != p_id);
    setState({linksDataEx: new_data });
*/
    forceReload();
  }
  const postRenewHandler = () => {
    // may be open approve window?
    forceReload(); 
  }

  const createHandler = () => {
    setCreateState({...createState, loading: true });
    dispatch("showForm", { component: "select_institution"} );
  }

  const onCloseGetInstitutionHandler = () => {
    setCreateState({...createState, loading: false });
    forceReload();    
  } 

/////////////////////////////////////////////////////
const columns_api = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    _render: (text) => <a>{text}</a>,
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => { 
      const status = REQUISITION_STATUSES.find((s) => s[0] === text);
      return <Tooltip title={status[2]}><span>{status[1]}</span></Tooltip>;
    },
  },
  {
    title: 'stored',
    dataIndex: 'stored',
    key: 'stored',
    render(s, record) {
          return {
            props: {
              style: { color: s ? "green" : "red" }
            },
            children: <b>{s?"":"not "}stored</b>
          };
    }

  },
  {
    title: 'bank',
    dataIndex: 'institution_id',
    key: 'institution_id',
  },
  {
    title: 'bank name',
    dataIndex: 'bank_name',
    key: 'bank_name',
  },
  {
    title: 'created',
    dataIndex: 'created',
    key: 'created',
    render: date => <>{moment(date).format("DD.MM.YYYY HH:mm:ss")} </>,
  },

  {
    title: 'accounts',
    dataIndex: 'accounts',
    key: 'accounts',
    render: (_, { accounts }) => (
      <>
        {accounts.map((a) => {
          return (<>[{a}]</>);
        })}
      </>
    ),
  },
  {
    title: "",
    render: (record) =>
      <div style={{ float: "right" }}>
        <Space size="small">
          {record.status === 'CR' && <LinkApproveBtn link={record} /> }
          {record.status === 'EX' && <LinkRenewBtn link={record} postRenewHandler={postRenewHandler}/> }
          <LinkDeleteBtn id={record.id} type="danger" stored={record.stored} removeHandler={removeHandler} />
        </Space>
      </div>
  }                                                     
];
/////////////////////////////////////////////////////

  console.log("GocardlessPage: props:", props);

  const { loading, error, data, refetch } = useQuery(GET_GOCARDLESS_PARAMETERS, {skip: !access });
  const [ linksLoading, linksError, linksData, linksReload ] = useFetch(api_url, "GET", null, {skip: !access });

  useEffect(() => {
    if (!data || !linksData?.results) return;

    setState({...state, preparing: true});

    console.log("GocardlessPage: useEffect: got all data ...");
    console.log('db data', data);
    console.log('api data', linksData);

    // join all data sources data
    const api_links = state?.linksDataEx ? state.linksDataEx : linksData;

    console.log('api_links:', api_links);

//debug only
/*
 * add FAKEd expired link for refresh (renew) testing
 * select any real link_id and set `ex` variable
 *
    if (!(state?.linksDataEx)) { 
        const ex = '8686e7ce-bcff-4b93-8077-8912243c8c21';
        const i = api_links.results.findIndex ((r) => r.id === ex);
        if (i > -1) { 
          const o = api_links.results [i];
          o.status = 'EX';
        }
    }
*/

/* 
        api_links.results.find ((r) => r.id = ex)
api_links.results[api_links.results.length] = 
 {
  id: 'ed871c1c-cf04-45ba-a5e6-0ed324717b04',
  created: '2024-01-30T10:50:33.459521Z',
  redirect: 'http://www.yourwebpage.com',
  status: 'EX',
  institution_id: 'SANDBOXFINANCE_SFIN0000',
  agreement: '',
  reference: 'ed871c1c-cf04-45ba-a5e6-0ed324717b04',
  accounts: [],
  link: 'https://bankaccountdata.gocardless.com/psd2/start/ed871c1c-cf04-45ba-a5e6-0ed324717b04/SANDBOXFINANCE_SFIN0000',
  ssn: null,
  account_selection: false,
  redirect_immediate: false,
  bank_name: 'debug',
};
*/
    const linksDataEx = { results: !api_links.results? [] : api_links.results.map((l) => {
      // const stored = data.links.some(e => e.id === l.id);
      const stored_link = data.links.find(e => e.link_id === l.id);
      console.log("stored_link: ", stored_link);
      return {...l, stored: stored_link?true:false, bank_name: stored_link?.bank_name? stored_link?.bank_name:l.bank_name};
     })
    };
    
    setState({...state, linksDataEx: linksDataEx, preparing: false});
    //setTabData (linksDataEx.results);

  }, [data, linksData]);

  if (loading || linksLoading) return "Loading ...";
  if (error || linksError) return `Error! ${error?.message? error.message : linksError}`;
  if (state.preparing) return "Preparing data ...";

  if (!access) return MESSAGE_NO_ACCESS;

  return <div>          
          <GetInstitution onClose={onCloseGetInstitutionHandler}/>
          <Space size="small">
            <Button
            type="primary"
            size="small"
            _icon={<PlusOutlined />}
            loading={createState.loading}
            onClick={createHandler}
            >
              Create link
            </Button>
  
            <Button
            type="primary"
            size="small"
            onClick={forceReload}
            >
              Refresh links
            </Button>
          </Space>
          <Table 
             columns={columns_api} 
             dataSource={state?.linksDataEx?.results||[]} 
             onRow={(record, index) => ({
               style: {
                  background: record.status === 'LN' ? '#00FFCC' : (record.status === 'EX' ? '#FFFF66' : 'default'),
               },
               onClick: event => {},
               onDoubleClick: event => {},
               onContextMenu: event => { event.preventDefault(); return false; }, // right button click row
               onMouseEnter: event => {}, // mouse enter row
               onMouseLeave: event => {}, // mouse leave row
             })}
          />
{/*
          <Table columns={columns_db} dataSource={data.links||[]} />
          <br/>
          <pre>[ {JSON.stringify(linksData, null, 2)} ]</pre>
          <pre>[ {JSON.stringify(data, null, 2)} ]</pre>
*/}
        </div>;
};

export default GocardlessPage;
