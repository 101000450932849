import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { Link } from "react-router-dom";
import { Button, Menu, SubMenuProps, Typography, Divider, Select, Space } from "antd";
import { logout, logout_all, isAdmin, getUserTenant /*, setUserTenant*/ } from "../../auth/authHelper";
import { ApiButton } from "../ApiButton";
import { GetUserButton } from "../GetUserButton";
import { UserProfile, profile } from "../users/UserProfile";
import { UserOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";

// after update antd to latest 4.x 
// Warning: [antd: Menu] `children` will be removed in next major version. 
// Please use `items` instead.
//
// TODO: rewrite MenuItemEx

// hide
// TODO: add Menu.SubMenu and hide it if no one visible MenuItemEx
/*
const MenuItemEx = (props) => {
  const { roles, children, ...otherProps } = props;
  const { auth } = useStoreon("auth");
  return roles && (roles === "*" || roles.includes(auth.user_role)) ? 
    <Menu.Item {...otherProps}>{children}</Menu.Item>
   : 
    <></>
   ;
};
*/

// disable
// TODO: add Menu.SubMenu and disable it if no one active MenuItemEx
const MenuItemEx = (props) => {
  const { roles, children, ...otherProps } = props;
  const { auth } = useStoreon("auth");
  const disabled = !(roles && (roles === "*" || roles.includes(auth.user_role)));
  return <Menu.Item {...otherProps} disabled={disabled}>{children}</Menu.Item>
};

export const MenuInvoice = props => {
  //const { dispatch } = useStoreon();
  const { dispatch, auth, tab } = useStoreon("auth", "tab");
  const client = useApolloClient();
  const [ menuKey, setMenuKey ] = useState();
  //const [ tenant, setTenant ] = useState(getUserTenant()||(tab&&tab.tennat));
  const [ tenant, setTenant ] = useState(tab.tenat);
  
  useEffect(() => {
    var path = window.location.href.split('/');
    setMenuKey(path.pop());
    console.log("AuthNavBar.useEffect: MOUNT tab:["+tenant+"]");
    //setTenant(getUserTenant()||(tab&&tab.tennat));
    setTenant(tab.tenant);
  }); 

  const handleMenuClick = e => {
    switch (e.key) {
      case "logout":
        logout(client);
        dispatch("setAuthData", { authenticated: false });
        break;
      case "logout-all":
        logout_all(client);
        dispatch("setAuthData", { authenticated: false });
        break;
      case "profile":
        dispatch("showForm", { component: "profile" })
        //showProfile();
        break;
      //default:
        //return null;
    }
    setMenuKey(e.key);
  };

  const onChangeTenantHandler = (v) => {
    // alert('onChangeTenantHandler: '+v);

///////////////////
///////////////////
    dispatch("setTabData", { ...tab, tenant: v,});
    // use only storeon 
    //setUserTenant(v);
    setTenant(v);

    client.resetStore().then(() => {
      console.log('onChangeTenantHandler: client.resetStore: DONE');
      //alert('onChangeTenantHandler: client.resetStore: DONE');

      // await client.refetchQueries({
/*
      client.refetchQueries({
         include: "all", // "active",
      }).then(() => {
        alert('onChangeTenantHandler: client.refetchQueries: DONE');
      });
*/
    });

/*
 * 
            if (getUserTenant() !== values.tenant) {
              //alert(`new tenant: client.clearStore: was [${getUserTenant()}] => [${values.tenant}]`);

debugger;
              // reset the cache without refetching active queries
              client.clearStore().then(() => {
                alert("client.clearStore: done");
                client.cache.reset();
                client.cache.evict({ id: "ROOT_QUERY"}); // , fieldName: "Companies" 
                client.cache.gc();

                client.queryManager.cache.reset();
                if (client.localstate?.cache) client.localstate.cache.reset();

              });
              setUserTenant(values.tenant);

              //alert(`new tenant: client.clearStore: now [${getUserTenant()}]`);
            }
*/

/////////////////////
///////////////////
  }

//    {(auth.user_role === 'admin') && 
//    {isAdmin() &&  ... not refreshed 
//    <Menu.Item key="profile" style={{ float: "right", color: 'blue', backgroundColor: 'black', cursor: 'default' }} disabled>

//  defaultValue={(tab && tab.tenant)||process.env.REACT_APP_DEFAULT_TENANT}
//        <Select size="small" style={{ width: 120 }} defaultValue={getUserTenant()||process.env.REACT_APP_DEFAULT_TENANT} onChange={onChangeTenantHandler}>

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      onClick={handleMenuClick}
      style={{ lineHeight: "44px" }}
      defaultSelectedKeys={[menuKey]}
      selectedKeys={[menuKey]}
    >
      <Space direction="horizontal">
      <Typography.Text style={{ whiteSpace: "nowrap", fontSize: "10px", color: "green" }} >{process.env.REACT_APP_NAME+"@"+process.env.REACT_APP_VERSION}{/*[{tab&&tab.tenant}]*/}</Typography.Text>
{/*
  * Tenants not implemented for this menu yet ...
  *
        <Select size="small" style={{ width: 120 }} value={tenant} onChange={onChangeTenantHandler}>
          <Select.Option key="florunner" value="florunner">FloRunner</Select.Option>
          <Select.Option key="primeflower" value="primeflower">PrimeFlowers</Select.Option>
        </Select>      
*/}
      </Space>

      <MenuItemEx key="invoices" roles={['invoice_manager']}>
        <Link to="/invoices/index.js">Invoices</Link>
      </MenuItemEx>

      <Menu.Item key="profile" style={{ marginLeft: 'auto' }} >
        <Button type="link" ><UserOutlined/> [{auth.login}]</Button>
      </Menu.Item>

    </Menu>
  );
};
