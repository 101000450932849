import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Row, Col, Space, message, Form, Input, Modal, Checkbox, Button } from "antd";
import Countries from "../shared/Countries";
import { isSupplier, isAdmin } from "../../auth/authHelper";

const WarehouseForm = () => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component } = ui.visibleForm;
  const { companyId, warehouseId } = selection;

  useEffect(() => {
    if (component !== "warehouse") return;

    if (action === "edit") {
      form.setFieldsValue(data?.warehouse);
    } else form.resetFields();
  });

  const closeForm = () => {
    dispatch("hideForm");
  };

  const { loading, error, data } = useQuery(GET_WAREHOUSES, {
    skip: !(component === "warehouse" && action === "edit"),
    variables: {
      id: warehouseId
    }
  });

  const { l, e, data: company_data } = useQuery(GET_COMPANY, {
    skip: !(component === "warehouse"),
    variables: {
      id: companyId
    }
  });

  useEffect(() => {
    if (company_data && action === "add") {
      if (!form.getFieldValue('country_id')) {
        form.setFieldsValue({country_id: company_data.company.country_id});
      }
    }
  }, [company_data]);


  const checkDefault = (ret) => {
    const o = (action === "add" ? ret.insert_warehouses : ret.update_warehouses).returning[0];
    // check previous value?
    if (!o.default_warehouse) {
	closeForm();
    } else {
        mutate_def({ variables: { 
      		id: o.id,
        	company_id: o.company_id 
		}
	})
        .catch(info => {
          console.log("Can`t reset default ", info);
          message.error("Can`t reset default");
        });
    }
  };

  const [mutate] = useMutation(
    action === "edit" ? UPDATE_WAREHOUSE : INSERT_WAREHOUSE,
    {
      variables: { id: action === "edit" ? warehouseId : undefined },
      refetchQueries: ["Companies", "Warehouses"], // Companies for tags
      //onCompleted: () => closeForm()
      onCompleted: (ret) => checkDefault(ret)
    }
  );

  const [mutate_def] = useMutation( UPDATE_WAREHOUSE_DEF,
    {
      refetchQueries: ["Warehouses"],
      onCompleted: () => closeForm()
    }
  );

  const populateFileds = () => {
    console.log(company_data?.company)
    form.setFieldsValue(company_data?.company);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {

        console.log("handleSubmit: values", values);
        delete values.__typename;
        values.company_id = companyId;

        if (!isAdmin()) {
          delete values.city;
          delete values.country_id;
          delete values.house_no;
          delete values.name;
          delete values.post_code;
          delete values.street;
          delete values.company_id;
        }
        mutate({ variables: { 
      		id: action === "edit" ? warehouseId : undefined,
		objects: values }
	});
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const perms = !isAdmin()? {disabled: true} : {};

  return (
    <Modal
      title="Warehouse form"
      visible={component === "warehouse"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeForm}
    >
      <Form
        layout="vertical"
        form={form}
        name="warehouse_form"
        initialValues={{ default_warehouse: true }}
      >
        <Button onClick={() => populateFileds()} type="link" {...perms} >
          Use company adress
        </Button>
        <Form.Item
          label="Warehouse name"
          name="name"
          rules={[{ required: true, message: "Please select warehouse name" }]}
        >
          <Input  {...perms} />
        </Form.Item>

        <Form.Item
          label="Street"
          name="street"
          rules={[
            { required: true, message: "Please select warehouse street" }
          ]}
        >
          <Input  {...perms} />
        </Form.Item>

        <Row gutter={8}>
        <Col span={12}>
        <Form.Item
          label="House"
          name="house_no"
          rules={[
            { required: true, message: "Please enter house number" }
          ]}
        >
          <Input maxLength="5"  {...perms} />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          label="Door_code"
          name="door_code"
          rules={[
            { required: false, message: "Please enter door code " }
          ]}
        >
          <Input />
        </Form.Item>
        </Col>
        </Row>

        <Row gutter={8}>
        <Col span={18}>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "Please select city" }]}
        >
          <Input  {...perms} />
        </Form.Item>
        </Col>
        <Col span={6}>
        <Form.Item
          label="Post code"
          name="post_code"
          rules={[{ required: true, message: "Please select post code" }]}
        >
          <Input maxLength="6"  {...perms} />
        </Form.Item>
        </Col>
        </Row>

        <Form.Item
          label="Route description"
          name="route_description"
          rules={[
            { required: false, message: "Please select route description" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country_id"
          rules={[{ required: true, message: "Please select country" }]}
        >
          <Countries  {...perms} />
        </Form.Item>

        <Form.Item name="default_warehouse" valuePropName="checked">
          <Checkbox>Default</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const INSERT_WAREHOUSE = gql`
  mutation CreateWarehouses($objects: [warehouses_insert_input!]!) {
    insert_warehouses(objects: $objects) {
      affected_rows
      returning { id company_id default_warehouse}
    }
  }
`;

const UPDATE_WAREHOUSE = gql`
  mutation UpdateWarehouse($id: Int!, $objects: warehouses_set_input!) {
    update_warehouses(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
      returning { id company_id default_warehouse}
    }
  }
`;

const UPDATE_WAREHOUSE_DEF = gql`
  mutation UpdateWarehouse($id: Int!, $company_id: Int!) {
    update_warehouses(
        _set: { default_warehouse: false } ,
        where: { _and: [
                { default_warehouse: {_eq: true } },
                { company_id: {_eq: $company_id} },
                { id: { _neq: $id } }
        ]}) {
      affected_rows
    }
  }

`;

const GET_WAREHOUSES = gql`
  query WarehouseById($id: Int!) {
    warehouse: warehouses_by_pk(id: $id) {
      id
      name
      street
      house_no
      post_code
      city
      door_code
      working_hours_from
      working_hours_till
      route_description
      company_id
      country_id
      default_warehouse
    }
  }
`;

const GET_COMPANY = gql`
  query CompanyById($id: Int!) {
    company: companies_by_pk(id: $id) {
      id
      role
      email
      name
      short_name
      street
      house_no
      city
      post_code
      country_id
      vat_nr
      registration_nr
    }
  }
`;

export default WarehouseForm;
