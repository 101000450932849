import React, { useState, useEffect } from "react";
import { AUTH_TOKEN } from "../../../constant";

const useFetch = (url, method = "GET", body, opts = {}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [reloadMod, setReloadMod] = useState(false);

  const reload = () => { 
    setData();
    setLoading(true);
    setReloadMod(!reloadMod); // inverse
  };

  useEffect(
    () => {
      if (opts.skip)  return [false, false, null, null];

      if (!url) return;

      fetch(url,
        {headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
          Accept: "application/json",
          "cache-control": "no-cache"
        },
        method: method, 
        body: body,
        })
        .then(data => data.json())
        .then(data => {
          setData(data)
          setLoading(false)
        })
        .catch(err => {
          setError(err.toString())
          setLoading(false)
        })
    },
    [url, reloadMod]
  );

  if (opts.skip)  return [false, false, null, null];

  return [loading, error, data, reload];
};

export default useFetch;
